import { HTTP } from '../../axios/axios'
import store from '../../store'
let data

async function getAll (lang) {
  try {
    const res = await HTTP.get('/links', { headers: { 'accept-language': lang } })
    data = res.data
    return res.data.links
  } catch (error) {
    console.log(error)
  }
}
async function addOne (link, lang) {
  data.links = [...data.links, link]
  return await HTTP.patch('/links/' + data._id, data, { headers: { 'accept-language': lang } })
}
async function update (link, id) {
  data.links = store.state.data
  return await HTTP.patch('/links/' + data._id, data, { headers: { 'accept-language': data.lang } })
}
async function deleteOne (link) {
  data.links = data.links.filter(i => i._id !== link._id)
  return await HTTP.patch('/links/' + data._id, data)
}
async function deleteMany (ids) {
  data.links = data.links.filter(i => ids.indexOf(i._id) === -1)
  return await HTTP.patch('/links/' + data._id, data)
}

export default { getAll, deleteMany, addOne, update, deleteOne }
