<template>
  <div>
    <div class="flex flex-wrap justify-between">
      <label for="language">Choose language:
        <select
          class="p-2 outline-none"
          v-model="lang"
          @change="getData(lang)"
          name="language"
        >
          <option value="en">English</option>
          <option value="ru">Russian</option>
          <option value="az">Azerbaijani</option>
        </select>
      </label>
    </div>
    <div class="p-4">
      <Table
        :lang="lang"
        :columnNames="['name', 'url', 'subLinks']"
        :keys="['name', 'url', 'subLinks']"
        @getData="getData(lang)"
        :requests="requests"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import requests from '../services/requests/LinksRequests.js'

export default {
  data () {
    return {
      lang: 'en',
      requests: requests
    }
  },
  name: 'Links',
  components: {
    Table
  },
  methods: {
    // gets data from backend
    async getData (lang) {
      this.$emit('loading', true)
      const temp = await this.requests.getAll(lang)
      this.$store.commit('setData', temp)
      this.$emit('loading', false)
    }
  },
  computed: {
    _ () {
      return _
    }
  },
  created () {
    // fills table with data from backend on created
    this.getData(this.lang)
  }
}
</script>
